<template>
  <div class="content">
    <!-- 无结果 -->
    <div class="contentmain" v-if="searchList.length == 0">
      <div class="breadcrumb">
        <div class="" style="">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/positionindex' }"
              >职位</el-breadcrumb-item
            >
            <el-breadcrumb-item>{{ pageInfo["keyword11"] }}</el-breadcrumb-item>
<!--            <el-breadcrumb-item>{{ pageInfo["keyword"] }}</el-breadcrumb-item>-->
            <!-- <el-breadcrumb-item>测试</el-breadcrumb-item> -->
          </el-breadcrumb>
        </div>
      </div>
      <div class="nodata">
        <img src="../../assets/images/common/search-no.png" />
        <div class="font18" v-if="!loading">抱歉，暂无匹配的职位</div>
        <div class="font18" v-if="loading">加载中</div>
      </div>
      <div class="s-tit font18" v-if="jobList1.length">职位推荐</div>
      <div class="" v-if="jobList1.length">
        <el-carousel height="139px" arrow="always" :autoplay="false">
          <el-carousel-item v-if="jobList1.length">
            <div class="likebox">
              <div
                class="likebox-main font14"
                @click="toJobDetail(sub)"
                v-for="(sub, index6) in jobList1"
                :key="index6">
                <div class="lm-one">
                  <div class="">{{ sub.jobName }} · {{ sub.city }}</div>
                  <div class="">
                    {{ (sub.salaryLow / 1000) | numFilter }}K-{{ (sub.salaryHigh / 1000) | numFilter }}K
                  </div>
                </div>
                <div class="lm-two">{{ sub.shortName }}</div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item v-if="jobList2.length">
            <div class="likebox">
              <div
                class="likebox-main font14"
                @click="toJobDetail(sub)"
                v-for="(sub, index6) in jobList2"
                :key="index6"
              >
                <div class="lm-one">
                  <div class="">{{ sub.jobName }} · {{ sub.city }}</div>
                  <div class="">
                    {{ (sub.salaryLow / 1000) | numFilter }}k-{{ (sub.salaryHigh / 1000) | numFilter }}K
                  </div>
                </div>
                <div class="lm-two">{{ sub.shortName }}</div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item v-if="jobList3.length">
            <div class="likebox">
              <div
                class="likebox-main font14"
                @click="toJobDetail(sub)"
                v-for="(sub, index6) in jobList3"
                :key="index6"
              >
                <div class="lm-one">
                  <div class="">{{ sub.jobName }} · {{ sub.city }}</div>
                  <div class="">
                    {{ (sub.salaryLow / 1000) | numFilter }}k-{{ (sub.salaryHigh / 1000) | numFilter }}K
                  </div>
                </div>
                <div class="lm-two">{{ sub.shortName }}</div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <!-- 有结果 -->
    <div class="" v-if="searchList.length">
      <div class="screenbox">
        <div class="screenbox-mian font16">
          <div class="breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/positionindex' }"
                >职位</el-breadcrumb-item
              >
              <el-breadcrumb-item>{{ pageInfo["keyword11"] }}</el-breadcrumb-item>
<!--              <el-breadcrumb-item>{{ pageInfo["keyword"] }}</el-breadcrumb-item>-->
              <!-- <el-breadcrumb-item>测试</el-breadcrumb-item> -->
            </el-breadcrumb>
          </div>
          <!-- 周期 -->
          <div class="cycle font16">
            <div class="cycle-main">
              <div class="font14">项目周期:</div>
              <el-date-picker
                type="date"
                placeholder="开始时间"
                v-model="startTime"
                style="width: 182px; margin-left: 10px"
              ></el-date-picker>
              <span style="margin: 0 15px">-</span>
              <el-date-picker
                type="date"
                placeholder="结束时间"
                v-model="endTime"
                style="width: 182px"
              ></el-date-picker>
            </div>
            <div class="clear" @click="clearPageInfo">清空筛选条件</div>
          </div>
          <!-- 地区 -->
          <!-- <div class="li qiye-type">
						<div class="tit">工作地点:</div>
						<span :class="areanum == item4.id ? 'active-c' : ''" v-for="(item4, index4) in arealist" :key="index4" @click="areanum = item4.id">{{ item4.name }}</span>
					</div> -->
          <!-- 薪资和学历和经验 -->
          <div class="preparation">
            <div class="zidingyi">
              <div class="d-name">薪资：</div>
              <div class="d-input">
                <el-select
                  v-model="pageInfo.salaryLow"
                  placeholder="请选择最低"
                  style="width: 130px"
                >
                  <el-option
                    v-for="item in gzLowOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <span>-</span>
                <el-select
                  v-model="pageInfo.salaryHigh"
                  placeholder="请选择最高"
                  style="width: 130px"
                >
                  <el-option
                    v-for="item in gzHighOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="education-select">
              <div class="d-name">学历：</div>
              <el-select
                v-model="selectEducations"
                multiple
                placeholder="请选择"
                @change="eduSelectLimit"
              >
                <el-option
                  v-for="item in educationOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="zidingyi">
              <div class="d-name">工作经验：</div>
              <div class="d-input">
                <el-select
                  v-model="pageInfo.expLow"
                  placeholder="请选择最低"
                  style="width: 130px"
                >
                  <el-option
                    v-for="item in expLowOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <span>-</span>
                <el-select
                  v-model="pageInfo.expHigh"
                  placeholder="请选择最高"
                  style="width: 130px"
                >
                  <el-option
                    v-for="item in expHighOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <el-button type="primary" @click="search">搜索</el-button>
          </div>
        </div>
      </div>
      <div class="contentmain">
        <!-- 有结果 -->
        <div class="">
          <div class="positionlist">
            <el-card
              class="box-card p-list"
              v-for="(itemli, indexes) in searchList"
              :key="indexes"
            >
              <div class="p-listbox" @click="todetail(itemli)">
                <div class="pl-left font14">
                  <div class="l-one">
                    <div class="font16">
                      {{ itemli.jobName }}
                      <span class="span-c-name">{{ itemli.shortName }}</span>
                      <span class="span-c-type" v-if="itemli.jobType == 1">Free</span>
                    </div>
                  </div>
                  <div class="l-two">
                    {{ itemli.city }} |
                    {{
                      itemli.educationType.length
                          ? itemli.educationType[0].itemText + " | "
                          : " 不限 | "
                    }}
                    {{ itemli.expLow }}-{{
                      itemli.expHigh}} 年 | {{ (itemli.salaryLow / 1000) | numFilter }}K-{{ (itemli.salaryHigh / 1000) | numFilter}}K
                  </div>
                  <!-- <div class="l-two">{{itemli.city}} | {{itemli.educationType.length ? itemli.educationType[0].itemText : '/'}} | {{itemli.expLow}}-{{itemli.expHigh}}年 | {{itemli.salaryLow/1000}}K-{{itemli.salaryHigh/1000}}K</div> -->
                  <div class="l-three" v-if="itemli.jobType != 2">
                    项目周期：{{
                      itemli.startDate
                        ? itemli.startDate.replace(/00:00:00/g, "")
                        : "/"
                    }}
                    —
                    {{
                      itemli.endDate
                        ? itemli.endDate.replace(/00:00:00/g, "")
                        : "/"
                    }}
                  </div>
                </div>
                <el-divider direction="vertical"></el-divider>
                <div class="pl-right font14">
                  <div class="time">
                    {{ itemli.updateDate.split(" ")[0] }}发布
                  </div>
                  <div class="synum">剩余招聘:{{ itemli.jobFree }}</div>
                  <!-- <div class="czbtn">
										<el-popover placement="bottom" width="150" trigger="click">
											<div class="downcode ">
												<img src="../../assets/logo.png" />
												<div class="downcodetext font14">快去扫码下载 APP操作吧！</div>
											</div>
											<el-button slot="reference">接受握手</el-button>
										</el-popover>
									</div> -->
                </div>
              </div>
            </el-card>
            <div class="nextpage" @click="getMoreList" v-if="moreBtn">
              点击加载更多
            </div>
          </div>
          <!-- <div class="paginate" style="position: relative;">
						<el-pagination
							@current-change="handleCurrentChange"
							:current-page="pageInfo.pageNum"
							:page-size="pageInfo.pageSize"
							layout="total, prev, pager, next, jumper"
							:total="pageInfo.count"
						></el-pagination>
					</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { options } from "../../common/options.js";
export default {
  data() {
    return {
      startTime: "",
      endTime: "",
      pageInfo: {
        pageNo: 1,
        pageSize: 10,
        city: "",
        userJobId: "",
        skillIds: "",
        start: "",
        end: "",
        salaryLow: "",
        salaryHigh: "",
        educationTypes: "",
        expLow: "",
        expHigh: "",
        keyword: "",
        keyword11:"",
      },
      selectEducations: [],
      educationOptions: [],
      expLowOptions: options.expLowOptions,
      expHighOptions: options.expHighOptions,
      gzLowOptions: options.gzLowOptions,
      gzHighOptions: options.gzHighOptions,
      searchList: [],
      jobList1: [],
      jobList2: [],
      jobList3: [],
      areanum: 1,
      loading: false,
      arealist: [
        { id: 1, name: "上城区" },
        { id: 2, name: "下城区" },
        { id: 3, name: "萧山区" },
        { id: 4, name: "临平区" },
        { id: 5, name: "西湖区" },
        { id: 6, name: "余杭区" },
        { id: 7, name: "临安区" },
        { id: 8, name: "富阳区" },
      ],
    };
  },
  created() {
    this.getEducation();
    if (sessionStorage.getItem("searchJobConfig")) {
      let searchJobConfig = JSON.parse(
        sessionStorage.getItem("searchJobConfig")
      );
      // console.log("search result,  created,  ----- search job config = ", searchJobConfig);
      this.selectEducations = searchJobConfig.selectEducations;
      for (let k in this.pageInfo) {
        this.pageInfo[k] = searchJobConfig.pageInfo[k];
      }
      if (sessionStorage.getItem("selectCity")) {
        this.pageInfo["city"] = sessionStorage.getItem("selectCity");
      }
      this.checkKeyword11(searchJobConfig['pageInfo']);
      // this.pageInfo.keyword11=this.pageInfo.keyword;
      // if(searchJobConfig['pageInfo'].salaryLow>=0&&searchJobConfig['pageInfo'].salaryHigh){
      //   // this.pageInfo["keyword"]+="|"+(searchJobConfig['pageInfo'].salaryLow+'-'+searchJobConfig['pageInfo'].salaryHigh);
      //   this.pageInfo.keyword11+=("|"+searchJobConfig['pageInfo'].salaryLow+'-'+searchJobConfig['pageInfo'].salaryHigh);
      // }
      // if(searchJobConfig['pageInfo'].educations){
      //   // this.pageInfo["keyword"]+="|"+searchJobConfig['pageInfo'].educations;
      //   this.pageInfo.keyword11+=("|"+searchJobConfig['pageInfo'].educations);
      // }
      // if(searchJobConfig['pageInfo'].workYear){
      //   // this.pageInfo["keyword"]+="|"+searchJobConfig['pageInfo'].workYear;
      //   this.pageInfo.keyword11+=("|"+searchJobConfig['pageInfo'].workYear);
      // }
      // if(searchJobConfig['pageInfo'].start){
      //   // this.pageInfo["keyword"]+="|"+searchJobConfig['pageInfo'].start.substring(0, 10);
      //   this.pageInfo.keyword11+=("|"+searchJobConfig['pageInfo'].start.substring(0, 10));
      //   if(searchJobConfig['pageInfo'].end){
      //     // this.pageInfo["keyword"]+="|"+searchJobConfig['pageInfo'].end.substring(0, 10);
      //     this.pageInfo.keyword11+=(" ~ "+searchJobConfig['pageInfo'].end.substring(0, 10));
      //   } else {
      //     this.pageInfo.keyword11+=("|");
      //   }
      // } else {
      //   if(searchJobConfig['pageInfo'].end){
      //     // this.pageInfo["keyword"]+="|"+searchJobConfig['pageInfo'].end.substring(0, 10);
      //     this.pageInfo.keyword11+=("|"+searchJobConfig['pageInfo'].end.substring(0, 10));
      //   }
      // }

      this.getSearchList();
      this.getJobList();
    }
  },
  mounted() {
    document.getElementById('main_id').scrollTop = 0;
    // window.addEventListener('scroll',this.handleScroll,true)
  },
  // destroyed() {
    // window.removeEventListener('scroll',this.handleScroll,true)
  // },
  filters: {
    numFilter(value) {
      let transformVal = Number(value).toFixed(2)
      let realVal = transformVal.substring(0, transformVal.length - 1)

      return Number(realVal)
    }
  },
  methods: {
    // handleScroll(){
      // chrome || firefox  || IE：  document.documentElement.scrollTop  document.body.scrollTop
      // safari: window.pageYOffset
    //   let scrollTop= document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
    //   console.log("@search result ----  handleScroll", scrollTop);
    // },
    checkKeyword11(searchJobConfig) {
      // console.log("checkout the keyword11 ----- config = ", searchJobConfig);
      let flag = false;
      this.pageInfo.keyword11 = "";
      if (searchJobConfig.salaryLow >= 0 && searchJobConfig.salaryHigh) {
        // this.pageInfo["keyword"]+=" | "+(searchJobConfig['pageInfo'].salaryLow+'-'+searchJobConfig['pageInfo'].salaryHigh);
        this.pageInfo.keyword11 += (searchJobConfig.salaryLow + '-' + searchJobConfig.salaryHigh);
        flag = true;
      }
      if (searchJobConfig.educations) {
        // this.pageInfo["keyword"]+=" | "+searchJobConfig['pageInfo'].educations;
        this.pageInfo.keyword11 += ((flag ? " | " : "") + searchJobConfig.educations);
        flag = true;
      }
      if (searchJobConfig.workYear) {
        // this.pageInfo["keyword"]+=" | "+searchJobConfig['pageInfo'].workYear;
        this.pageInfo.keyword11 += ((flag ? " | " : "") + searchJobConfig.workYear);
        flag = true;
      }
      if (searchJobConfig.expLow && searchJobConfig.expHigh) {
        this.pageInfo.keyword11 += ((flag ? " | " : "") + searchJobConfig.expLow + "~" + searchJobConfig.expHigh + "年");
        flag = true;
      }
      if (searchJobConfig.start) {
        // this.pageInfo["keyword"]+=" | "+searchJobConfig['pageInfo'].start.substring(0, 10);
        this.pageInfo.keyword11 += ((flag ? " | " : "") + searchJobConfig.start.substring(0, 10));
        if (searchJobConfig.end) {
          // this.pageInfo["keyword"]+=" | "+searchJobConfig['pageInfo'].end.substring(0, 10);
          this.pageInfo.keyword11 += (" ~ " + searchJobConfig.end.substring(0, 10));
        } else {
          this.pageInfo.keyword11 += ((flag ? " | " : ""));
        }
        flag = true;
      } else {
        if (searchJobConfig.end) {
          // this.pageInfo["keyword"]+=" | "+searchJobConfig['pageInfo'].end.substring(0, 10);
          this.pageInfo.keyword11 += ((flag ? " | " : "") + searchJobConfig.end.substring(0, 10));
          flag = true;
        }
      }
      if (searchJobConfig.keyword) {
        let keywordList = searchJobConfig.keyword.split(" ");
        if (keywordList && keywordList.length > 0) {
          keywordList.map( key => {
            if (key.trim() && key.trim().length > 0) {
              this.pageInfo.keyword11 += ((flag ? " | " : "") + key.trim());
              flag = true;
            }
          })
        } else {
          this.pageInfo.keyword11 += ((flag ? " | " : "") + searchJobConfig.keyword);
          flag = true;
        }
      }
      // console.log("checkout the keyword11 ----- pageInfo = ", this.pageInfo);
    },
    eduSelectLimit(val){
      if(val.length>2){
        this.$message.warning("最多只能匹配两项！");
        this.selectEducations.splice(-1);
      }
    },
    getEducation() {
      //获取学历
      this.$api.getdict("get", { code: "education_type" }).then((res) => {
        res.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.name,
          };
          this.educationOptions.push(obj);
        });
      });
    },
    getSearchList() {
      let config = JSON.parse(JSON.stringify(this.pageInfo));

      if (config.start) {
        let start = new Date(config.start);
        let y = start.getFullYear();
        let m = start.getMonth() + 1;
        let d = start.getDate();
        config.start = y + "-" + m + "-" + d;
      }
      if (config.end) {
        let end = new Date(config.end);
        let y = end.getFullYear();
        let m = end.getMonth() + 1;
        let d = end.getDate();
        config.end = y + "-" + m + "-" + d;
      }
      this.loading = true;
      this.checkKeyword11(config);
      config.educationTypes = this.selectEducations.join(",");
      // console.log("getSearchList,  search Job, current config = ", config);
      this.$api.searchJob("get", config).then((res) => {
        this.loading = false;

        this.searchList = this.searchList.concat(res.data);

        if (res.data.length < 10) {
          this.moreBtn = false;
        } else {
          this.moreBtn = true;
        }
      });
    },
    getMoreList() {
      //下一页
      this.pageInfo.pageNo++;
      this.getSearchList();
    },
    search() {
      //搜索
      this.searchList = [];
      this.pageInfo.pageNo = 1;
      this.getSearchList();
    },
    getJobList() {
      //获取推荐职位
      let config = {
        isHand: 0,
        userJobId: this.pageInfo.userJobId,
        pageNo: 1,
        pageSize: 12,
      };
      this.$api.getJobList("get", config).then((res) => {
        res.data.map((item, index) => {
          if (index < 4) {
            this.jobList1.push(item);
          } else if (index < 8) {
            this.jobList2.push(item);
          } else {
            this.jobList3.push(item);
          }
        });
      });
    },
    clearPageInfo() {
      //清空筛选条件
      this.pageInfo.start = "";
      this.pageInfo.end = "";
      this.pageInfo.salaryLow = "";
      this.pageInfo.salaryHigh = "";
      this.pageInfo.educationTypes = "";
      this.pageInfo.expLow = "";
      this.pageInfo.expHigh = "";
      this.selectEducations = [];
      this.$router.push({ path: '/positionindex'});
    },
    handleCurrentChange(val) {
      this.fetchData(val);
    },
    toJobDetail(data) {
      this.$router.push({
        path: "/positiondetail",
        query: { id: data.id, userJobId: this.pageInfo.userJobId },
      });
    },
    todetail(data) {
      this.$router.push({
        path: "/positiondetail",
        query: {
          id: data.id,
          userJobId: this.pageInfo.userJobId,
          enderType: "search",
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  margin-bottom: 150px;
}
/deep/.el-card {
  border-radius: 0px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
}
/deep/.el-carousel__indicators--horizontal {
  display: none;
}
.likebox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  .likebox-main {
    margin: 0 10px;
    width: 20%;
    margin-bottom: 30px;
    padding: 0 10px;
    background-color: #ffffff;
    .lm-one {
      //display: flex;
      align-items: center;
      //justify-content: space-between;
      color: #111111;
      line-height: 30px;
      border-bottom: 1px dashed #dddddd;
      padding: 10px 0;
      div:last-child {
        color: #ff8400;
      }
    }
    .lm-two {
      color: #666666;
      padding: 20px 0;
    }
  }
}

.breadcrumb {
  padding: 20px 0 0 0;
}
.el-breadcrumb {
  font-size: 14px;
}
.screenbox {
  // height: 600px;
  background-color: #ffffff;
  padding-bottom: 20px;
  .screenbox-mian {
    width: 1200px;
    margin: 0 auto;
    transform: translate(8px, 0px);

    .cycle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      border-bottom: 1px solid #dddddd;
      .cycle-main {
        color: #111111;
        display: flex;
        align-items: center;
        /deep/ .el-input__inner {
          border-radius: 0px;
        }
      }
      .clear:hover {
        cursor: pointer;
      }
      .clear {
        color: #666666;
      }
    }
    .li {
      padding: 20px 0;
    }
    .qiye-type {
      color: #111111;
      border-bottom: 1px solid #dddddd;
      display: flex;
      align-items: center;
      span:hover {
        cursor: pointer;
        color: #00bcff;
      }
      span {
        margin-left: 30px;
      }
      .active-c {
        color: #00bcff;
      }
    }
    .preparation {
      display: flex;
      align-items: center;
      padding-top: 20px;
      .preparation-main {
        margin-right: 70px;
        .el-dropdown-link:hover {
          cursor: pointer;
        }
      }
      .zidingyi {
        margin-right: 50px;
        display: flex;
        align-items: center;
        .d-name {
          color: #606266;
          font-size: 14px;
          margin-right: 10px;
        }
        .d-input {
          display: flex;
          align-items: center;
          span {
            margin: 0 10px;
          }
          /deep/ .el-input__inner {
            border-radius: 0px;
          }
        }
      }
      .education-select {
        display: flex;
        align-items: center;
        margin-right: 50px;
        .d-name {
          color: #606266;
          font-size: 14px;
          margin-right: 10px;
        }
      }
    }
  }
}
.s-tit {
  color: #111111;
  margin: 30px 0;
}
.nodata {
  background-color: #ffffff;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 80px 0 130px 0;
  margin-top: 20px;
  div {
    color: #111111;
    margin-top: 20px;
  }

  img {
    width: 260px;
    height: 220px;
  }
}
.positionlist {
  margin-top: 20px;
  .nextpage {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999999;
    cursor: pointer;
    margin-top: 15px;
  }
  .p-list {
    margin-bottom: 20px;
    .p-listbox {
      display: flex;
      align-items: center;
      /deep/.el-divider {
        background: #dddddd;
      }
      /deep/.el-divider--vertical {
        height: 24px;
      }
      .pl-left {
        display: flex;
        flex: 1;
        // align-items: center;
        flex-direction: column;
        line-height: 2;
        .l-one {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          div {
            color: #111111;
            .span-c-name{
              color: #ff8400;
              padding: 0 1px 0 9px;
            }
            .span-c-type {
              background-color: #e9f9ff;
              color: #00bcff;
              padding: 1px 1px 1px 9px;
              margin: 0 10px;
              font-size: 14px;
            }
          }
        }
        .l-two {
          color: #666666;
        }
        .l-three {
          color: #666666;
        }
      }
      .pl-right {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        color: #666666;
        .time {
        }
        .synum {
          margin: 0 70px;
          span {
            color: #111111;
          }
        }
        .czbtn {
          /deep/ .el-button {
            border-radius: 0px;
            width: 100px;
            height: 36px;
            background-color: #00bcff;
            border: none;
            color: #ffffff;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
.downcode {
  text-align: center;
  .downcodetext {
    color: #111111;
    margin-top: 5px;
    padding: 0 30px;
  }
  img {
    width: 120px !important;
    height: 120px !important;
  }
}

.paginate {
  margin: 100px 0 60px 0 !important;
  .el-pagination {
    padding: 0 !important;
  }
}
</style>
